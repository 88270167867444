import React from 'react'
import "./advisory-coucil.css"
import { Link } from "react-router-dom";
import {AiOutlineLinkedin} from "react-icons/ai"
import alysa from "../images/Alysa.jpeg"
import jac from "../images/jacquelyn.jpeg"
import deb from "../images/deborah.jpeg"
import Natalie from "../images/Natalie.jpeg"
import amy from "../images/amy.jpeg"
import greg from "../images/greg.jpeg"
import edgar from "../images/edgar.jpeg"
import heather from "../images/heather.jpeg"
import shamsah from "../images/shamsaN.PNG"
import chris from "../images/chris.jpeg"
import bryan from "../images/bryan-kenna.jpg"

const Advisory_Council = () => {
  const divData = [
    {
      id: 1,
      imageUrl: <img src={alysa} alt='' className="img-fluid mainImgPort" />, 
      title: 'Alyssa Reinhart',
      linkedin: 'https://www.linkedin.com/in/alyssareinhart/',
      description: 'Strengthen educational pathways into the workforce',
    },
    {
      id: 2,
      imageUrl: <img src={jac} alt='' className="img-fluid mainImgPort" />,
      title: 'Jacquelyn Schulz',
      linkedin:'https://www.linkedin.com/in/jacquelyncraddock/',
      description: 'Workforce Initiatives, U.S. CSG Strategic Operations at CGI      ',
    },{
      id: 3,
      imageUrl: <img src={deb} alt='' className="img-fluid mainImgPort" />,
      title: 'Deborah Kobes',
      linkedin:'https://www.linkedin.com/in/deborahkobes/',
      description: 'Senior Fellow, Apprenticeship and Workforce',
    },{
      id: 4,
      imageUrl: <img src={Natalie} alt='' className="img-fluid mainImgPort" />,
      title: 'Natalie Miller',
      linkedin:'https://www.linkedin.com/in/nataliemillersphr/',
      description: 'Talent Management & Development Fanatic| Relationship Developer | Sales & Marketing Leader | HR',
    },{
      id: 5,
      imageUrl: <img src={amy} alt='' className="img-fluid mainImgPort" />,
      title: 'Amy Mackenroth',
      linkedin: 'https://www.linkedin.com/in/amymackenroth/',
      description: 'Shaping strategies and opportunities for future workforce growth and innovation with partners nationally.',
    },{
      id: 6,
      imageUrl: <img src={greg} alt='' className="img-fluid mainImgPort" />,
      title: 'Greg Vaughn',
      linkedin:'https://www.linkedin.com/in/texasassociationworkforceboard/',
      description: 'Executive Director, TexasAssociation of Workforce Board ',
    },{
      id: 7,
      imageUrl: <img src={edgar} alt='' className="img-fluid mainImgPort" />,
      title: 'Edgar Padilla',
      linkedin:'https://www.linkedin.com/in/padillaedgar/',
      description: 'Consulting Manager',
    },{
      id: 8,
      imageUrl: <img src={heather} alt='' className="img-fluid mainImgPort" />,
      title: 'Heather Terenzio',
      linkedin: "https://www.linkedin.com/in/terenzio/",
      description: 'Senior Executive in Workforce Development with a passion for Start-ups and Entrepreneurship',
    },{
      id: 9,
      imageUrl: <img src={shamsah} alt='' className="img-fluid mainImgPort" />,
      title: 'Shamsah Noorani',
      linkedin: "https://www.linkedin.com/in/shamsahn/",
      description: 'General Manager   Health Tech Academy',
    },{
      id: 10,
      imageUrl: <img src={chris} alt='' className="img-fluid mainImgPort" />,
      title: 'Chris Lofton',
      linkedin: "https://www.linkedin.com/in/chris-lofton-83997431/",
      description: 'General Manager   Health Tech Academy',
    },
    {
      id: 11,
      imageUrl: <img src={bryan} alt='' className="img-fluid mainImgPort" />,
      title: 'Bryan Kenna',
      linkedin: "https://www.linkedin.com/in/brianlambert/",
      description: 'CEO at QuickStart QuickStart Inc.',
    },
  ];


  return ( 
    <div className=' light-bg'>
      <div className='container'>
      <div className='hero-text-clr ad-text text-center fw-bold text-uppercase pt-3'>
        <div>Workforce</div> Development 
      </div>
      <div className='darkO-txt  text-center fw-bold ad-text2'>ADVISORY COUNCIL</div>
      </div>
      <div className="container mt-5">
      <div className="row  custom-width  mx-auto">
        
        {divData.slice(0, 3).map((item) => (
          <div key={item.id} className="col-lg-4 col-md-6 mb-4 px-4 ">
            <div className="mb-4 pb-4 border mx-2 mainRowCol px-4">
            <div class="card-content d-flex flex-column position-absolute mainLinkEdin ">
                        <Link to={item.linkedin} target="_blank">
        <div className="btnadvisory "><AiOutlineLinkedin size={50} className="mt-3 p-2 btn-I-ad"></AiOutlineLinkedin></div>
      </Link>
                    </div>
             <div>{item.imageUrl}</div>
              <h3 className='mt-3 fs-5'>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
        
      </div>
      <div className="row ">
        {divData.slice(3, 11).map((item) => (
          <div key={item.id} className="col-lg-3 col-md-6 mb-4 px-4">
            <div className="mb-4 pb-4 border mx-2  mainRowCol px-4">
            <div class="card-content d-flex flex-column position-absolute mainLinkEdin ">
                        <Link to={item.linkedin} target="_blank">
        <div className="btnadvisory "><AiOutlineLinkedin size={50} className="mt-3 p-2 btn-I-ad"></AiOutlineLinkedin></div>
      </Link>
                    </div>
                    <div>{item.imageUrl}</div>
              <h3 className='mt-3 fs-5'>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    

    </div>
    </div>
  )
}

export default Advisory_Council
