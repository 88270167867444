import React from 'react';
import StrategyMission from '../Components/StrategyComponents/strategyMission/StrategyMission';
import Enterpren from '../Components/StrategyComponents/Entreprene/Entreprene';


function Strategy() {
  return (
   <>
   <StrategyMission/>
   <Enterpren/>
 
   </>
  );
}

export default Strategy;